import React, { useEffect, useRef } from 'react';
import Divider from '@mui/material/Divider';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ElliLogo from '../../assets/image/elli-logo.svg';
import LoginIcon from '../../assets/image/login.svg';
import VerifyCustomer from '../VerifyCustomer/VerifyCustomer';
import WelcomeImage from '../../assets/image/welcome.svg';
import { config } from '../../config';
import useCRMStyles from '../CRMPanel/useStyles';
import useStyles from './useStyles';
import {
  useCCUserRolesQuery,
  resetCCUserRolesQuery,
} from '../../state/queries/ccUserRoles';
import { LoadingButton } from '@mui/lab';
import { useWrappedAuth0Hook } from '../../custom-hooks/useWrappedAuth0Hook';
import { useCCUserInfoQuery } from '../../state/queries/ccUserInfo';

const InvalidRole = ({ uid, email }: { uid: string; email: string }) => {
  const classes = useStyles();
  const userIdRef = useRef<HTMLInputElement>(null);

  const copyText = (ref: React.RefObject<HTMLInputElement>): void => {
    (ref.current as HTMLInputElement).select();
    document.execCommand('copy');
  };

  const sendToEmail = () => {
    const mailtoString = `
      mailto:request-cc-access@elli.eco?Subject=Grant access permissions for user ${email} to Contact Center App ${config.env}&body=Grant the following user permissions to access the Contact Center app:
      %0A%0A
      User Email: ${email}%0A
      IAM id: ${uid}%0A
      Env: ${config.env}%0A
    `;
    window.open(mailtoString);
  };

  return (
    <div
      data-testid='invalid-role-component'
      className={classes.invalidRoleContextBox}
    >
      <h4 className={classes.insufficentPermissionsText}>
        <p className={classes.topText}>
          Your account does not have sufficient permissions.
        </p>
        <p className={classes.bottomText}>
          Request access through{' '}
          <span className={classes.mailtoLink} onClick={() => sendToEmail()}>
            request-cc-access@elli.eco
          </span>{' '}
          by referencing your user account id from below.
        </p>
      </h4>
      <Divider className={classes.divider} />

      <div className={classes.userDetailsWrapper}>
        <div className={classes.accountEmail}>
          <h2 className={classes.emailTitle}>User account email</h2>
          <p className={classes.emailText}>{email}</p>
        </div>

        {uid && (
          <div className={classes.textWrapper}>
            <span className={classes.title}>User account id</span>
            <TextField
              InputProps={{
                classes: {
                  input: classes.textBox,
                  root: classes.inputRoot,
                  focused: classes.inputFocused,
                },
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => copyText(userIdRef)}
                      className={classes.copyIcon}
                      data-testid='id-copy-button'
                      disableFocusRipple
                      disableRipple
                      size='large'
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                ref: userIdRef,
                readOnly: true,
                'data-testid': 'user-id',
              }}
              defaultValue={uid}
              variant='outlined'
              size='small'
            />
          </div>
        )}
      </div>
    </div>
  );
};

const LoggedOut = ({
  login,
  isLoading,
}: {
  login: () => Promise<void>;
  isLoading: boolean;
}): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <div data-testid='logged-out-warning' className={classes.loggedOutBox}>
        <h4 className={classes.invalidTitle}>
          You’ve been logged out. To continue log in again.
        </h4>
      </div>
      <LoadingButton
        data-testid='login-button'
        onClick={() => login()}
        color='primary'
        variant='contained'
        className={classes.loginButton}
        loading={isLoading}
      >
        <img src={LoginIcon} alt='log in' className={classes.buttonIcon} />
        <span>Login</span>
      </LoadingButton>
    </>
  );
};

const LogoComponent = (): JSX.Element => {
  const sharedCrmClasses = useCRMStyles({});
  return (
    <div className={sharedCrmClasses.logoContainer}>
      <img src={ElliLogo} alt='elli logo' />
    </div>
  );
};

const WelcomeImageComponent = (): JSX.Element => {
  const sharedCrmClasses = useCRMStyles({});
  return (
    <div className={sharedCrmClasses.welcomeImageContainer}>
      <img src={WelcomeImage} alt='Welcome' />
    </div>
  );
};

interface IOwnProps {
  verifyDisplayState: boolean;
  emailFromURL: string;
  width?: number | undefined;
  setUserDataLoading: (x: boolean) => void;
}

const WelcomePanel: React.FC<IOwnProps> = ({
  verifyDisplayState,
  emailFromURL,
  width,
  setUserDataLoading,
}) => {
  const {
    loginWithPopup,
    isAuthenticated,
    logout: auth0Logout,
    user,
  } = useWrappedAuth0Hook();
  const classes = useStyles();
  const ccUserRolesQuery = useCCUserRolesQuery();
  const resetCCUserRoles = resetCCUserRolesQuery();
  const { data: ccUserInfo } = useCCUserInfoQuery();

  useEffect(() => {
    if (isAuthenticated) {
      ccUserRolesQuery.refetch();
    } else {
      resetCCUserRoles();
    }
  }, [isAuthenticated]);

  const logout = () => {
    auth0Logout({ returnTo: window.location.origin });
  };

  const login = async () => {
    try {
      await loginWithPopup({ connection: 'Elli-AAD' });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const renderUserVerification = () => (
    <div className={classes.welcomePanel}>
      <LogoComponent />
      <WelcomeImageComponent />
      <VerifyCustomer
        setDataLoading={setUserDataLoading}
        emailFromURL={emailFromURL}
        startOpen={verifyDisplayState}
        width={width}
        fullSizeButton={true}
      />
      <span
        data-testid='logout-button'
        className={classes.logoutLink}
        onClick={() => logout()}
      >
        Logout
      </span>
    </div>
  );

  const renderDefaultWelcome = () => (
    <div className={classes.welcomePanel}>
      <LogoComponent />
      <WelcomeImageComponent />
      <LoggedOut login={login} isLoading={ccUserRolesQuery.isInitialLoading} />
    </div>
  );

  const renderInvalidRole = (userId: string, email: string) => (
    <div className={classes.invalidRoleWelcomePanel}>
      <LogoComponent />
      <WelcomeImageComponent />
      <InvalidRole uid={userId} email={email} />
    </div>
  );

  const renderContent = () => {
    if (ccUserRolesQuery?.data?.isCCManager) {
      return renderUserVerification();
    } else if (ccUserInfo?.iamId && user?.email) {
      return renderInvalidRole(ccUserInfo.iamId, user.email);
    }
    return renderDefaultWelcome();
  };

  return <>{isAuthenticated ? renderContent() : renderDefaultWelcome()}</>;
};

export default WelcomePanel;
