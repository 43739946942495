import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ICoupon } from '../../types/paymentMethods';
import { PaymentDetailsService } from '../../services/paymentDetails';
import toast from 'react-hot-toast';

export const useGetCoupons = (
  iamId: string | undefined
): UseQueryResult<ICoupon> => {
  return useQuery(
    ['userCoupons', iamId],
    async () => {
      const coupons = await PaymentDetailsService.getCoupons(iamId!);
      return {
        ...coupons,
      };
    },
    {
      onError: () => {
        toast.error('Failed to load Multi Purpose Vouchers');
      },
      enabled: !!iamId,
    }
  );
};
