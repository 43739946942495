import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';
import { PublicChargingService } from '../../services/publicCharging';
import {
  IPublicChargingSession,
  IRemoteStopResponse,
} from '../../types/publicCharging';
import assert from 'assert';

export const usePublicChargingSessionsQuery = (
  userId?: string
): UseQueryResult<IPublicChargingSession[]> => {
  return useQuery(
    ['publicChargingSessions', userId],
    async (): Promise<IPublicChargingSession[]> => {
      assert(
        userId !== undefined,
        'usePublicChargingSessionsQuery: userId is undefined'
      );
      const sessions = await PublicChargingService.getChargingSessions(userId);
      return sessions.sort(
        (a, b) =>
          new Date(b.start_datetime).getTime() -
          new Date(a.start_datetime).getTime()
      );
    },
    {
      enabled: !!userId,
      refetchInterval: 5000,
    }
  );
};

export const useStopChargingSessionQuery = (
  userId: string,
  evseId: string
): UseMutationResult<IRemoteStopResponse> => {
  return useMutation(
    async () => await PublicChargingService.stopSession(userId, evseId)
  );
};

export const useDismissChargingSessionQuery = (
  userId: string,
  evseId: string
): UseMutationResult<undefined> => {
  return useMutation(
    async () => await PublicChargingService.terminateSession(userId, evseId)
  );
};
