import { AxiosResponse } from 'axios';
import toast from 'react-hot-toast';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { InvoiceService } from '../../services/invoices';

export const useInvoicePdfQuery = (
  subscriberId: string | undefined,
  invoiceId: string | undefined
): UseQueryResult<AxiosResponse<Blob>> => {
  return useQuery(
    ['invoicePdf', invoiceId],
    async () => {
      return await InvoiceService.getInvoicePdf(subscriberId!, invoiceId!);
    },
    {
      onError: () => {
        toast.error('Failed to load invoice pdf');
      },
      enabled: !!subscriberId && !!invoiceId,
    }
  );
};
