import { Button } from '@mui/material';
import MultilineButton from '../../shared/Buttons/MultilineButton';
import React, { useContext, useState } from 'react';
import { useStyles } from './useStyles';
import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { DialogContextState } from '../../shared/Dialogs/context/ActionDialogContext';

export type OwnProps = {
  confirmAction: () => Promise<void>;
};

const ButtonGroup: React.FC<OwnProps> = ({ confirmAction }) => {
  const { t, terms } = useTypedTranslation();

  const classes = useStyles();
  const { handleCloseDialog } = useContext(DialogContextState);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const handleConfirmation = async () => {
    try {
      setInProgress(true);
      await confirmAction();
      handleCloseDialog && handleCloseDialog();
    } catch (err) {
      setInProgress(false);
    }
  };

  return (
    <div className={classes.actions}>
      <Button
        color='primary'
        variant='text'
        className={classes.button}
        onClick={handleCloseDialog}
      >
        {t(terms.general.cancel)}
      </Button>
      <MultilineButton
        inProgress={inProgress}
        handleClick={handleConfirmation}
        mainText={'Confirm'}
        className={classes.button}
      />
    </div>
  );
};

export default ButtonGroup;
