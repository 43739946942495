import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';
import { ICDRMgmtResponse } from '../../../../types/chargingSession';
import decimalToComma from '../../../../utils/decimalToComma';
import CpoGroupDialog from './CpoGroupDialog';
import { ChargingCondition } from '../../../../types/chargingCondition';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BlockingFeeTable from './BlockingFeeTable';
import parseSeconds from '../../../../utils/parseSeconds';

interface IOwnProps {
  chargeRecord: ICDRMgmtResponse;
  chargingCondition: ChargingCondition;
}

const BreakdownTable: React.FC<IOwnProps> = ({
  chargeRecord,
  chargingCondition,
}) => {
  const classes = useStyles();
  const [isCpoGroupDialogOpen, setIsCpoGroupDialogOpen] = useState(false);

  const priceBreakdown = chargeRecord.price_breakdown;

  const formattedZero = decimalToComma(Number(0).toFixed(2));

  let fixedConsumptionPrice: string | undefined;

  let billableBlockingPeriod = { start: '', end: '' };

  if (chargeRecord.location_connector_power_type === 'DC') {
    fixedConsumptionPrice = chargingCondition.fixed_pricing_dc_price.toFixed(2);
    if (chargingCondition.blocking_fee_dc) {
      billableBlockingPeriod = {
        start: chargingCondition.blocking_fee_dc?.application_start_local_time,
        end: chargingCondition.blocking_fee_dc?.application_end_local_time,
      };
    }
  } else {
    fixedConsumptionPrice = chargingCondition.fixed_pricing_ac_price.toFixed(2);
    if (chargingCondition.blocking_fee_ac) {
      billableBlockingPeriod = {
        start: chargingCondition.blocking_fee_ac?.application_start_local_time,
        end: chargingCondition.blocking_fee_ac?.application_end_local_time,
      };
    }
  }

  const consumptionFee = priceBreakdown?.charging_fee?.gross_amount
    ? decimalToComma(
        Number(priceBreakdown?.charging_fee?.gross_amount).toFixed(2)
      )
    : formattedZero;

  const couponReduction = priceBreakdown?.coupon_reduction
    ? `- ${decimalToComma(priceBreakdown?.coupon_reduction.toFixed(2))}`
    : formattedZero;

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell size='small'>
              <Typography variant='h6'>Item</Typography>
            </TableCell>
            <TableCell align='right' size='small'>
              <Typography variant='h6'>Total</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ alignContent: 'flex-start' }}>
              <Typography variant='subtitle2' sx={{ display: 'inline' }}>
                Energy price according to consumption
              </Typography>
              {chargeRecord.cpo_party_id && (
                <>
                  <IconButton
                    data-testid='price-info-button'
                    onClick={() =>
                      setIsCpoGroupDialogOpen((prevState) => !prevState)
                    }
                    disableRipple
                    disableFocusRipple
                    size='large'
                  >
                    <HelpOutlineIcon />
                  </IconButton>
                  <CpoGroupDialog
                    open={isCpoGroupDialogOpen}
                    onClose={() => setIsCpoGroupDialogOpen(false)}
                    cpoPartId={chargeRecord.cpo_party_id}
                  />
                </>
              )}
            </TableCell>
            <TableCell align='right'>
              <div>
                <Typography variant='subtitle2'>
                  {consumptionFee} {chargeRecord.total_price_currency}
                </Typography>
                <Typography variant='h6'>
                  {`
                  ${
                    chargingCondition.fixed_pricing_unit === 'min' ||
                    chargingCondition.fixed_pricing_unit === 's'
                      ? parseSeconds(chargeRecord.total_time * 60 * 60)
                      : `${chargeRecord.total_energy} ${chargingCondition.fixed_pricing_unit}`
                  } 
                  * 
                  ${decimalToComma(fixedConsumptionPrice)}
                  ${chargingCondition.currency} /
                  ${chargingCondition.fixed_pricing_unit}
                  `}
                </Typography>
              </div>
            </TableCell>
          </TableRow>

          <BlockingFeeTable
            chargeRecord={chargeRecord}
            billableBlockingPeriod={billableBlockingPeriod}
          />

          <TableRow>
            <TableCell>
              <Typography variant='subtitle2'>Transaction Fee</Typography>
            </TableCell>
            <TableCell align='right'>
              <div>
                <Typography variant='subtitle2'>
                  {`${decimalToComma(
                    chargingCondition.transaction_fee.toFixed(2)
                  )} ${chargingCondition.currency}`}
                </Typography>
              </div>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography variant='subtitle2'>
                Voucher amount applied
              </Typography>
            </TableCell>
            <TableCell align='right'>
              <div>
                <Typography variant='subtitle2'>
                  {couponReduction} {priceBreakdown?.currency}
                </Typography>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align='right'>
              <Typography variant='h6'>
                (VAT is included in the total price)
              </Typography>
              <Typography variant='subtitle2'>
                <span style={{ marginRight: '50px' }}>Total</span>
                <span>
                  {`
                  ${decimalToComma(
                    priceBreakdown?.total_price?.gross_amount.toFixed(2) ||
                      formattedZero
                  )} 
                  ${priceBreakdown?.currency}
                  `}
                </span>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default BreakdownTable;
