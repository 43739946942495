import { ActionInterface } from '../actions';
import { State } from '..';
import isEqual from 'lodash/isEqual';
import update from 'immutability-helper';
import { generateUniqueString } from '../../../../utils/generateUniqueString';

export default (state: State, action: ActionInterface.AddBreadcrumb): State => {
  const breadcrumbsState = state || {
    breadcrumbs: [],
  };

  const lastCrumb =
    breadcrumbsState.breadcrumbs[breadcrumbsState.breadcrumbs.length - 1];

  if (lastCrumb) {
    if (
      lastCrumb.component === action.data.component &&
      lastCrumb.componentId === action.data.componentId
    ) {
      if (
        action.data.component !== 'ChargingStationsCollection' &&
        action.data.component !== 'ChargingSessionsCollection'
      ) {
        return {
          ...state,
        };
      }

      if (
        action.data.component === 'ChargingStationsCollection' &&
        action.data.org &&
        lastCrumb.org
      ) {
        return {
          ...state,
        };
      }

      if (
        action.data.component === 'ChargingSessionsCollection' &&
        isEqual(action.data.defaultFilters, lastCrumb.defaultFilters)
      ) {
        return {
          ...state,
        };
      }
    }
  }

  const id = generateUniqueString();

  const newBreadcrumb = Object.assign({}, action.data, {
    id,
    current: true,
  });

  const oldCurrent = breadcrumbsState.breadcrumbs.findIndex((bc) => bc.current);

  let updatedBreadcrumbs = update(breadcrumbsState, {
    breadcrumbs: {
      $splice: [
        [
          action.data.level,
          breadcrumbsState.breadcrumbs.length - 1,
          newBreadcrumb,
        ],
      ],
    },
  });

  if (oldCurrent > -1) {
    updatedBreadcrumbs = update(updatedBreadcrumbs, {
      breadcrumbs: {
        [oldCurrent]: {
          $merge: {
            current: false,
          },
        },
      },
    });
  }

  return {
    ...state,
    ...updatedBreadcrumbs,
  };
};
