import React from 'react';
import { useTypedTranslation } from '../../../../custom-hooks/useTypedTranslation';
import connector, { IPropsFromState } from '../../../Connector/Connector';
import { Alert, FormControl, InputLabel } from '@mui/material';
import { SubscribersService } from '../../../../services/subscribers';
import { toast } from 'react-hot-toast';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogHeadline from '../../../shared/Dialogs/DialogHeadline';
import { BlockingReasons } from '../../../../utils/blockingReasons';
import { useStyles } from '../useStyles';
import ButtonGroup from '../ButtonGroup';

const BlockPublicChargingAccessDialog: React.FC<IPropsFromState> = ({
  subscriberState,
  getSubscriberOnlyRequest,
}) => {
  const { t, terms } = useTypedTranslation();
  const [blockingReason, setBlockingReason] = React.useState(
    'MANUAL_FRAUD_REVIEW' // set this as initial value because it was used all the time
  );
  const [issue, setIssue] = React.useState(false);

  const classes = useStyles();

  const subscriber = subscriberState.data;

  const onConfirm = async () => {
    if (subscriber && !subscriber.blocked) {
      if (blockingReason !== '') {
        try {
          await SubscribersService.blockPublicChargingAccess(
            subscriber.id,
            blockingReason
          );
          getSubscriberOnlyRequest({
            userId: subscriber.iam_id,
          });
        } catch (err) {
          toast.error(t(terms.general_errors.generic));
          throw new Error(
            'Unexpected error. Could not block public charging access.'
          );
        }
      } else {
        setIssue(true);
      }
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setBlockingReason(event.target.value as string);
  };

  return (
    <div className={classes.outerWrapper}>
      <div>
        <DialogHeadline
          headline={t(terms.public_charging_access.actions.block.headline)}
        />
      </div>
      <div className={classes.infoText}>
        {t(terms.public_charging_access.actions.block.text)}
      </div>
      <FormControl className={classes.form}>
        <InputLabel id='selected-blocking-reason-label'>
          Selected blocking reason
        </InputLabel>
        <Select
          labelId={'selected-blocking-reason-label'}
          id={'blocking-reason-select'}
          value={blockingReason}
          onChange={handleChange}
          data-testid={'blocking-reason-select'}
        >
          {BlockingReasons.map((br) => (
            <MenuItem key={br} value={br}>
              {br}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonGroup confirmAction={async () => onConfirm()} />
      {issue && (
        <Alert severity='error' data-testid='enableDisableButtonErrorAlert'>
          Please select a blocking reason before blocking a customer
        </Alert>
      )}
    </div>
  );
};

export default connector(BlockPublicChargingAccessDialog);
