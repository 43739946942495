import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { PaymentStatus } from '../../types/payment';
import { RefundService } from '../../services/refunds';
import { InvoiceService } from '../../services/invoices';
import { TransactionState, TransactionType } from '../../types/transaction';

const useGetInvoicePaymentStatus = (
  invoiceNumber: string,
  invoiceState: TransactionState
): UseQueryResult<PaymentStatus> => {
  return useQuery(
    ['invoiceStatus', invoiceNumber],
    async () => {
      return await InvoiceService.getInvoicePaymentStatus(invoiceNumber);
    },
    {
      enabled: invoiceState === TransactionState.Completed,
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
    }
  );
};

const useGetCreditNotePaymentStatus = (
  creditNoteNumber: string,
  creditNoteState: TransactionState
): UseQueryResult<PaymentStatus> => {
  return useQuery(
    ['creditNoteStatus', creditNoteNumber],
    async () => {
      return await RefundService.getCreditNotePaymentStatus(creditNoteNumber);
    },
    {
      enabled: creditNoteState === TransactionState.Completed,
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
    }
  );
};

export const useGetPaymentStatus = (
  transactionType: TransactionType,
  transactionNumber: string,
  transactionState: TransactionState
): UseQueryResult<PaymentStatus> => {
  switch (transactionType) {
    case TransactionType.Invoice:
      return useGetInvoicePaymentStatus(transactionNumber, transactionState);
    case TransactionType.CreditNote:
      return useGetCreditNotePaymentStatus(transactionNumber, transactionState);
    default:
      throw new Error('Invalid transaction type');
  }
};
