import { StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import React from 'react';

import { useTypedTranslation } from '../../../custom-hooks/useTypedTranslation';
import { getLanguage } from '../../../utils/translation/i18n';
import { datePickerLocaleMap } from './localeMap';

interface IDatePickerProps {
  label: string;
  onChange: (date: Date | null) => void;
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
  maxDate?: Date;
  minDate?: Date;
  className?: string;
  inputId?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
}

export const DatePicker: React.FC<IDatePickerProps> = (
  pickerProps: IDatePickerProps
) => {
  const { t, terms } = useTypedTranslation();

  return (
    <LocalizationProvider
      locale={datePickerLocaleMap[getLanguage()] ?? datePickerLocaleMap.en}
      dateAdapter={AdapterDateFns}
    >
      <StaticDatePicker
        views={['day']}
        displayStaticWrapperAs='desktop'
        disableCloseOnSelect={false}
        value={null}
        mask={t(terms.shared_components.date_picker.format.mask)}
        inputFormat={t(terms.shared_components.date_picker.format.technical)}
        renderInput={(params) => <TextField {...params} />}
        {...pickerProps}
      />
    </LocalizationProvider>
  );
};
