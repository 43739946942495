import { AxiosError } from 'axios';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { RefundService } from '../../services/refunds';
import { ICreditNote } from '../../types/creditNote';
import { IInvoiceRefund } from '../../types/invoice';

export const useInvoiceRefund = (): UseMutationResult<
  ICreditNote,
  AxiosError,
  {
    subscriberId: string;
    invoiceId: string;
    data: IInvoiceRefund;
  }
> =>
  useMutation(({ subscriberId, invoiceId, data }) =>
    RefundService.postRefund(subscriberId, invoiceId, {
      ...data,
      line_items: data.line_items.map((item) => ({
        invoice_line_item_id: item.invoice_line_item_id,
        gross_amount_to_refund: item.gross_amount_to_refund.toFixed(2),
      })),
    })
  );
