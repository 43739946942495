import toast from 'react-hot-toast';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { ChargingCondition } from '../../types/chargingCondition';
import { ChargingConditionService } from '../../services/chargingCondition';

export const useGetChargingCondition = (
  chargingConditionId?: string
): UseQueryResult<ChargingCondition> => {
  return useQuery(
    ['chargingCondition', chargingConditionId],
    () =>
      chargingConditionId
        ? ChargingConditionService.getByChargingConditionId(chargingConditionId)
        : undefined,
    {
      enabled: !!chargingConditionId,
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast.error('Charging condition cannot be fetched!');
      },
    }
  );
};
