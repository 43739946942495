import ApiService from './api';

export const pairRFIDCardAndSubscription = async (
  subscriptionId: string,
  rfidCardID: string
) => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/subscriptions/${subscriptionId}/pairrfidcard`,
    {
      method: 'POST',
      data: {
        rfid_card_number: rfidCardID,
      },
    }
  );

  const { data } = await apiService.query();
  return data;
};

export const rfidCardAction = async (
  action: 'activate' | 'deactivate' | 'archive' | 'remove',
  rfidCardId: string
) => {
  const apiService = new ApiService(
    `/mgmt/customer/v1/rfidcards/${rfidCardId}/${action}`,
    {
      method: 'POST',
    }
  );

  await apiService.query();
};
