import toast from 'react-hot-toast';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { TariffService } from '../../services/tariffs';
import { ITariffConditionModel } from '../../types/tariff';

export const useGetTarrif = (
  tariffId?: string,
  baseConditionId?: string
): UseQueryResult<ITariffConditionModel> => {
  return useQuery(
    ['tariff', tariffId, baseConditionId],
    () =>
      tariffId && baseConditionId
        ? TariffService.getByConditionId(tariffId, baseConditionId)
        : undefined,
    {
      enabled: !!tariffId && !!baseConditionId,
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast.error('Tariff cannot be fetched!');
      },
    }
  );
};
