import toast from 'react-hot-toast';
import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { SubscribersService } from '../../services/subscribers';
import { ICDRMgmtResponse } from '../../types/chargingSession';

export const useGetChargeRecord = (
  externalCdrId?: string,
  cpoPartyId?: string,
  cpoCountryCode?: string
): UseQueryResult<ICDRMgmtResponse> => {
  return useQuery(
    ['chargeRecord', externalCdrId, cpoPartyId, cpoCountryCode],
    () =>
      externalCdrId && cpoPartyId && cpoCountryCode
        ? SubscribersService.getChargeRecord(
            externalCdrId,
            cpoPartyId,
            cpoCountryCode
          )
        : undefined,
    {
      enabled: !!externalCdrId && !!cpoPartyId && !!cpoCountryCode,
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast.error('Charge Record details cannot be fetched!');
      },
    }
  );
};
