import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { LocationService } from '../../services/locations';
import { get } from 'lodash';
import { ILocationModel } from '../../types/chargingSession';

type LocationIdentifiers = {
  locationId?: string;
  evseId?: string;
};

export const useChargingLocation = (
  locationIdentifier: LocationIdentifiers
): UseQueryResult<ILocationModel> => {
  const evseId = get(locationIdentifier, 'evseId');
  const locationId = get(locationIdentifier, 'locationId');
  return useQuery(
    ['location', locationIdentifier],
    async () => {
      const locations = locationId
        ? await LocationService.getByLocationId(locationId)
        : evseId
        ? await LocationService.getById(evseId)
        : undefined;
      return locations?.[0];
    },
    {
      enabled: locationId !== undefined || evseId !== undefined,
    }
  );
};
