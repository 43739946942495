import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useRef, useState } from 'react';

import WallboxNotFound from '../../../assets/image/wallboxInfoNotFound.svg';
import { useWallboxConfigDataQuery } from '../../../state/queries/wallboxMetaData';
import useStyles from './useStyles';
import { Typography } from '../../shared/Typography/Typography';

type TOwnProps = {
  serialNumber: string;
};

export const WallboxResultCard: React.FC<TOwnProps> = ({ serialNumber }) => {
  const classes = useStyles();
  const tableBody = useRef<null | HTMLTableSectionElement>(null);
  const [tooltip, setTooltip] = useState('Copy');

  const wallboxConfigDataQuery = useWallboxConfigDataQuery(serialNumber);

  const createData = (
    description: string,
    value: string | undefined
  ): { description: string; value: string } => {
    return { description, value: value ?? '-' };
  };

  const getRows = () => [
    createData('Pairing Code', wallboxConfigDataQuery.data?.pairingCode),
    createData('Model', wallboxConfigDataQuery.data?.model),
    createData('Serial number', wallboxConfigDataQuery.data?.serialNumber),
    createData(
      'Wi-fi Hotspot SSID',
      wallboxConfigDataQuery.data?.wifiHotSpotSSID
    ),
    createData(
      'Wifi Hotspot Password',
      wallboxConfigDataQuery.data?.wifiHotSpotPassword
    ),
    createData(
      'Network Host name',
      wallboxConfigDataQuery.data?.networkHostName
    ),
    createData(
      'Configuration page IP-Address',
      wallboxConfigDataQuery.data?.configurationIpAdress
    ),
    createData(
      'Configuration page Password',
      wallboxConfigDataQuery.data?.configurationIpPassword
    ),
    createData('Puk Code', wallboxConfigDataQuery.data?.pukCode),
  ];

  const handleTableCopy = () => {
    const range = document.createRange();
    const sel = window.getSelection();

    if (sel && tableBody.current) {
      sel.removeAllRanges();
      range.selectNode(tableBody.current);
      sel.addRange(range);
      document.execCommand('copy');
      sel.removeAllRanges();
      setTooltip('Copied!');
    }
  };

  const handleTooltipClose = () => {
    setTooltip('Copy');
  };

  const wallboxLoadingComponent = () => (
    <div className={classes.loadingContainer} data-testid='metaDataLoading'>
      <CircularProgress
        className={classes.loadingItem}
        variant={'indeterminate'}
        size={100}
        thickness={0.8}
      />
    </div>
  );

  const wallboxDetailsComponent = () => (
    <>
      <TableContainer
        className={classes.contentContainer}
        component={Paper}
        data-testid='metaDataTable'
      >
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableAlignLeft}>
                Wallbox Information
              </TableCell>
              <TableCell className={classes.tableAlignRight}>
                <Tooltip
                  TransitionProps={{ timeout: 0 }}
                  title={tooltip}
                  placement='top'
                  arrow
                  onClose={handleTooltipClose}
                >
                  <IconButton
                    onClick={handleTableCopy}
                    data-testid='copyButton'
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={tableBody}>
            {getRows().map((row) => (
              <TableRow key={row.description}>
                <TableCell
                  className={`${classes.tableAlignLeft}} ${classes.tableDescription}`}
                >
                  {row.description}
                </TableCell>
                <TableCell className={classes.tableAlignLeft}>
                  {row.value}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const wallboxNotFoundComponent = () => (
    <div className={classes.errorContainer} data-testid='metaDataError'>
      <img src={WallboxNotFound} alt='Not Found' />
      <div className={classes.errorTextBox}>
        <Typography className={classes.errorTextHeadline} variant='h2'>
          No wallbox found
        </Typography>
        <Typography className={classes.errorTextElement} variant='body2'>
          Looks like there aren’t any wallboxes that match your search. <br />
          Please check the serial number and ensure you have entered it
          corrrectly, including any dashes, or enter a new one.
        </Typography>
      </div>
    </div>
  );

  if (wallboxConfigDataQuery.status === 'success') {
    if (wallboxConfigDataQuery.data) {
      return wallboxDetailsComponent();
    } else {
      return wallboxNotFoundComponent();
    }
  } else if (
    wallboxConfigDataQuery.status === 'loading' ||
    wallboxConfigDataQuery.status === 'error'
  ) {
    return wallboxLoadingComponent();
  } else {
    return null;
  }
};
