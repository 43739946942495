import { TableRow, TableCell } from '@mui/material';
import React from 'react';
import useStyles from './useStyles';
import { Typography } from '../../../shared/Typography/Typography';
import { ICDRMgmtResponse } from '../../../../types/chargingSession';
import decimalToComma from '../../../../utils/decimalToComma';
import { differenceInMinutes, format } from 'date-fns';
import { getBlockingIntervals } from '../../../../utils/getBlockingIntervals';

interface IOwnProps {
  chargeRecord: ICDRMgmtResponse;
  billableBlockingPeriod: { start: string; end: string };
}

const BlockingFeeTable: React.FC<IOwnProps> = ({
  chargeRecord,
  billableBlockingPeriod,
}) => {
  const classes = useStyles();
  const priceBreakdown = chargeRecord.price_breakdown;

  if (!priceBreakdown || !priceBreakdown.blocking_fee) {
    return <></>;
  }

  const blockingFee = priceBreakdown.blocking_fee;
  // Since blocking_price_per_minute is same for all fixed price CDRs, we can use the first blocking_fee_item
  const blockingFeePerMinute =
    priceBreakdown.blocking_fee.blocking_fee_items[0].blocking_price_per_minute
      .gross_amount;
  const totalBlockingFee = blockingFee
    ? blockingFee.billed_blocking_minutes * blockingFeePerMinute
    : 0;

  const blockingIntervals = getBlockingIntervals(
    new Date(chargeRecord.start_date_time),
    new Date(chargeRecord.end_date_time),
    billableBlockingPeriod.start,
    billableBlockingPeriod.end
  );

  const formattedZero = decimalToComma(Number(0).toFixed(2));

  return (
    <>
      <TableRow>
        <TableCell size='small'>
          <Typography variant='subtitle2'>Blocking Fee</Typography>
        </TableCell>
        <TableCell align='right' size='small'>
          <Typography variant='subtitle2'>
            {decimalToComma(totalBlockingFee.toFixed(2))}{' '}
            {chargeRecord.blocking_fee_currency}
          </Typography>
        </TableCell>
      </TableRow>

      {blockingIntervals.map((item) => (
        <TableRow key={item.start.getTime()}>
          <TableCell size='small' className={classes.blockingFeeCells}>
            <Typography variant='body2'>
              {`
              ${format(item.start, 'HH:mm')} - 
              ${format(item.end, 'HH:mm')} 
              ${item.label}
              `}
            </Typography>
          </TableCell>
          <TableCell
            align='right'
            size='small'
            className={classes.blockingFeeCells}
          >
            <Typography variant='h6' sx={{ paddingRight: '20px' }}>
              {differenceInMinutes(item.end, item.start)} mins
            </Typography>
          </TableCell>
        </TableRow>
      ))}

      <TableRow>
        <TableCell size='small' className={classes.blockingFeeCells}>
          <Typography variant='body2'>
            <i>Free blocking minutes</i>
          </Typography>
        </TableCell>
        <TableCell
          align='right'
          size='small'
          className={classes.blockingFeeCells}
        >
          <Typography variant='h6' sx={{ paddingRight: '20px' }}>
            {blockingFee?.free_blocking_minutes || 0} mins
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell size='small' className={classes.blockingFeeCells}>
          <Typography variant='body2'>
            <i>Total billed blocking minutes * Blocking fee per minute</i>
          </Typography>
        </TableCell>
        <TableCell
          align='right'
          size='small'
          className={classes.blockingFeeCells}
        >
          <Typography variant='h6' sx={{ paddingRight: '20px' }}>
            {`
              ${blockingFee?.billed_blocking_minutes || 0} mins * 
              ${decimalToComma(
                blockingFeePerMinute.toFixed(2) || formattedZero
              )} 
              ${chargeRecord.cpo_price_currency}
            `}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};

export default BlockingFeeTable;
