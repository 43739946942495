import { IUserModel } from './user';
import { IPlugAndChargeInfo } from './plugAndCharge';

export type ISubscriberModel = {
  billing_info_completed?: boolean;
  address?: IAddressModel;
  created_at: string;
  fleet_id: string;
  iam_id: string;
  id: string;
  payment_account_no: string;
  status: string;
  tenant_id: string;
  updated_at: string;
  customer_number?: string;
  plug_and_charge: IPlugAndChargeInfo;
  tax_number?: string;
  // rename to blocked, because  it does not make sense to have a property prefixed with subscriber inside a subscriber object: const blocked = subscriber.subscriber_blocked
  blocked: boolean;
  blocking_log: IBlockingLog[];
  prevent_subscription: boolean;
};

export interface IBlockingLog {
  created_at: string;
  blocked: boolean;
  reason: string;
}

export interface IShippingAddressModel {
  address_line_1?: string;
  address_line_2: string;
  city: string;
  country_code: string;
  house_number: string;
  street: string;
  zip: string;
}

export interface IAddressModel {
  id: string;
  city: string;
  country_code: string;
  zip: string;
  created_at: string;
  updated_at: string;
  address_line_2?: string;
  address_line_1?: string;
  state?: string;
}

export type RfidCardOrderStatus =
  | 'pending'
  | 'approved'
  | 'rejected'
  | 'printed'
  | 'shipped'
  | 'completed';

export type RfidCardStatus =
  | 'active'
  | 'inactive'
  | 'ordered'
  | 'readytopair'
  | 'archived';

export interface IRFIDCardModel {
  htb_white_list?: string;
  id: string;
  number: string;
  plasticard_brand_id: number;
  plasticard_design_template: number;
  status: RfidCardStatus;
  subscription_id: string;
  subscriber_id: string;
  tenant_id: string;
  tenant_name: string;
  tag: string;
  updated_at?: string;
  public_charging?: boolean;
  created_at: string;
  pairing_date?: string;
  label?: string;
}

export const isActivatableCard = (card: IRFIDCardModel): boolean =>
  (['archived', 'inactive'] as RfidCardStatus[]).includes(card.status);

export const isArchivableCard = (card: IRFIDCardModel): boolean =>
  (['active', 'inactive'] as RfidCardStatus[]).includes(card.status);

export const isDeactivatableCard = (card: IRFIDCardModel): boolean =>
  (['active'] as RfidCardStatus[]).includes(card.status);

export interface ICardOrderRequestModel {
  created_at: string;
  first_name: string;
  id: string;
  language_code: string;
  last_name: string;
  rfid_card: Omit<IRFIDCardModel, 'created_at'>;
  shipping_address: IShippingAddressModel;
  status: RfidCardOrderStatus;
  subscriber_id: string;
  updated_at: string;
  plasticard_salutation: number;
  reason: string;
  subscription_id?: string;
  ordered_by?: string;
}

export interface ICardOrderModel extends ICardOrderRequestModel {
  orderer?: IUserModel;
}

export type ISubscriptionModel = {
  automatic_renewal: boolean;
  base_condition_id: string;
  created_at: string;
  currency: string;
  end_date: string;
  expiration_reminder_sent_at: string;
  id: string;
  inactivation_reason?: string;
  monthly_fee_net_amount: number;
  monthly_fee_vat_amount: number;
  monthly_fee_vat_percentage: number;
  monthly_fee: number;
  notice_period_end_date: string;
  revocation_reason?: string;
  sign_up_fee_net_amount: number;
  sign_up_fee_vat_amount: number;
  sign_up_fee_vat_percentage: number;
  sign_up_fee: number;
  start_date: string;
  status: string;
  subscriber_id: string;
  tariff_id: string;
  updated_at: string;
  voucher_details?: {
    total_credit: number;
    remaining_credit: number;
    last_used_at?: string;
    currency: string;
  };
};

export type SubscriptionStatus =
  | RfidCardStatus
  | RfidCardOrderStatus
  | 'cancelled'
  | 'upcoming';

export const getSubscriptionStatus = (
  subscription: ISubscriptionModel
): SubscriptionStatus =>
  subscription.inactivation_reason === 'revoked'
    ? 'cancelled'
    : (subscription.status as SubscriptionStatus);

const PublicChargingAccessStatus = ['blocked', 'active'] as const;
export type PublicChargingAccessStatus = typeof PublicChargingAccessStatus[number];
