import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IPlugAndChargeEvent } from '../../types/plugAndCharge';
import { PlugAndChargeService } from '../../services/plugAndCharge';
import { queryClient } from '../queryClient';

export const usePlugAndChargeEventHistoryQuery = (
  emaid: string
): UseQueryResult<IPlugAndChargeEvent[]> => {
  return useQuery(['plugAndChargeEventHistory', emaid], () =>
    PlugAndChargeService.getEventHistory(emaid)
  );
};

export const resetPlugAndChargeEventHistoryQuery = (emaid: string): void => {
  queryClient.resetQueries(['plugAndChargeEventHistory', emaid]);
};
