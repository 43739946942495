import React from 'react';
import Grid from '@mui/material/Grid';
import DialogContainer from '../../../../../shared/Dialogs/DialogContainer/DialogContainer';
import SuccessMessage from '../../../../../shared/Dialogs/SuccessMessage';
import Message, { MessageTypes } from '../../../../../shared/Message';
import ContactDetailsIcon from '../../../../../../assets/image/contactDetails.svg';
import { AxiosError } from 'axios';
import { Dispatcher } from '../../../../../Connector/dispatchers';
import { ActionInterface } from '../../../../../../store/state/breadcrumbs/actions';
import { useTypedTranslation } from '../../../../../../custom-hooks/useTypedTranslation';
import { IErrorData } from '../../../../../../services/error';

type TInvoiceRefundResponseProps = {
  type: 'subscription' | 'charge_record';
  error: AxiosError | null;
  isError: boolean;
  isSuccess: boolean;
  removeBreadcrumb: Dispatcher<ActionInterface.RemoveBreadcrumb>;
};

const InvoiceRefundResponse: React.FC<TInvoiceRefundResponseProps> = ({
  type,
  error,
  isError,
  isSuccess,
  removeBreadcrumb,
}) => {
  const { t, terms } = useTypedTranslation();

  const navigateBackToInvoiceOverview = () => {
    removeBreadcrumb({ position: 2 });
  };

  const successDialogText: Record<
    TInvoiceRefundResponseProps['type'],
    string
  > = {
    subscription: t(terms.refunds.form.subscriptionDialogText),
    charge_record: t(terms.refunds.form.chargeRecordDialogText),
  };

  return (
    <>
      <Grid container alignItems='center' justifyContent='center'>
        {isError && (
          <Message
            message={{
              type: MessageTypes.ERROR,
              text:
                (error?.response?.data as IErrorData).errors[0].message ??
                t(terms.refunds.form.error),
            }}
          />
        )}
      </Grid>
      <DialogContainer
        isOpen={isSuccess}
        onClose={navigateBackToInvoiceOverview}
      >
        <SuccessMessage
          headline={t(terms.refunds.form.success)}
          iconAltText={t(terms.refunds.form.success)}
          subHeadline={successDialogText[type]}
          icon={ContactDetailsIcon}
        />
      </DialogContainer>
    </>
  );
};

export default InvoiceRefundResponse;
