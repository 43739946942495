import React from 'react';
import {
  AssetTable,
  IColumn,
  IRow,
} from '../../shared/AssetTable/AssetTable/AssetTable';
import { parseConsumption } from './helpers';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ElliTooltip } from '../../shared/ElliTooltip/ElliTooltip';
import { IconButton } from '@mui/material';
import { getTypeIcon } from '../../../utils/getTypeIcon';
import { formatIsoDates } from '../../../utils/dates';
import { mapFieldsForTable } from '../../../utils/mapFieldsForTable';
import useSharedStyles from '../../shared/useSharedStyles';
import { IBreadcrumbHandlerProps } from '../../../utils/addBreadcrumbHandler';
import { usePublicChargingSessionsQuery } from '../../../state/queries/usePublicChargingSessions';
import { ChargingSessionStatusComponent } from '../../ChargingSessionStatus/ChargingSessionStatusComponent';
import { ChargingSessionDuration } from '../../ChargingSessionDuration/ChargingSessionDuration';
import { StationDisplayName } from './StationDisplayName';
import ChargingSessionsIcon from '../../../assets/image/chargingSessions.svg';
import { UserStateModel } from '../../../store/state/user';
import { Typography } from '../../shared/Typography/Typography';

interface IOwnProps {
  addBreadcrumbHandler: (props: IBreadcrumbHandlerProps) => void;
  userState: UserStateModel;
}

export const ChargingSessions: React.FC<IOwnProps> = ({
  userState,
  addBreadcrumbHandler,
}) => {
  const userId = userState.data?.id;
  const sharedClasses = useSharedStyles();
  const getColumns = (): IColumn[] => {
    return mapFieldsForTable(
      {
        public: {
          name: 'Session Type',
          noPadding: true,
          align: 'right',
        },
        start_date_time: {
          name: 'Session Start',
        },
        last_updated_cpo: {
          name: 'Last updated by CPO',
        },
        total_time: {
          name: 'Duration',
        },
        total_energy: {
          name: 'Consumption',
        },
        location_evse_id: {
          name: 'Station',
        },
        status: {
          name: 'Status',
        },
        actions: {
          name: '',
        },
      },
      []
    );
  };

  const { data: chargingSessions } = usePublicChargingSessionsQuery(userId);

  if (
    userId === undefined ||
    chargingSessions === undefined ||
    chargingSessions.length < 1
  ) {
    return <div data-testid='charging-sessions-loading'></div>;
  }

  const getRows = (): IRow[] => {
    return Object.values(chargingSessions).map((cs) => {
      return {
        data: {
          public: {
            content: getTypeIcon({
              type: 'public',
              color: '#878C96',
              fontSize: 14,
              marginBottom: 2,
            }),
          },
          start_date_time: {
            content: (
              <div data-testid='start-date-time'>
                {formatIsoDates(cs.start_datetime)}
              </div>
            ),
          },
          last_updated_cpo: {
            content: (
              <div data-testid='last-updated-cpo'>
                {formatIsoDates(cs.last_updated_cpo)}
              </div>
            ),
          },
          total_time: {
            content: (
              <div data-testid='total-time'>
                <ChargingSessionDuration
                  session_status={cs.status}
                  start_datetime={cs.start_datetime}
                  end_datetime={cs.end_datetime}
                  last_updated_cpo={cs.last_updated_cpo}
                ></ChargingSessionDuration>
              </div>
            ),
          },
          total_energy: {
            content: (
              <div data-testid='total-energy'>
                <ElliTooltip
                  title={parseConsumption(cs.energy_consumption_in_kwh ?? 0)}
                >
                  <span data-testid='consumption'>
                    {parseConsumption(cs.energy_consumption_in_kwh ?? 0)}
                  </span>
                </ElliTooltip>
              </div>
            ),
          },
          location_evse_id: {
            content: (
              <StationDisplayName
                evse_id={cs.location_info.evse_id}
                location_id={
                  cs.location_info.location_id || cs.location_info.evse_id
                }
                station_name={cs.location_info.evse_id}
                addBreadcrumbHandler={addBreadcrumbHandler}
              ></StationDisplayName>
            ),
          },
          status: {
            content: (
              <div data-testid='status'>
                <ChargingSessionStatusComponent
                  status={cs.status}
                ></ChargingSessionStatusComponent>
              </div>
            ),
          },
          actions: {
            content: (
              <IconButton
                data-testid='charging-session-link'
                onClick={() =>
                  addBreadcrumbHandler({
                    component: 'PublicChargingSessionDetails',
                    friendlyText: 'Charging Session',
                    id: cs.id,
                    config: {
                      user_id: userId,
                    },
                  })
                }
                color='primary'
                size='large'
              >
                <ChevronRightIcon fontSize='large' />
              </IconButton>
            ),
          },
        },
        id: cs.id,
      };
    });
  };

  return (
    <>
      <div className={sharedClasses.headingWithIcon}>
        <img
          src={ChargingSessionsIcon}
          alt='Recent Sessions'
          className={sharedClasses.titleIcon}
        />
        <Typography
          variant='h1'
          classes={{
            root: sharedClasses.sectionTitle,
          }}
        >
          Recent Sessions
        </Typography>
      </div>
      <div data-testid='charging-sessions-table'>
        <AssetTable bulkActions={[]} columns={getColumns()} rows={getRows()} />
      </div>
    </>
  );
};

export default ChargingSessions;
