import { ActionInterface } from '../actions';
import { State } from '..';
import update from 'immutability-helper';

export default (
  state: State,
  action: ActionInterface.RemoveBreadcrumb
): State => {
  const breadcrumbsState = state;

  const oldCurrent = breadcrumbsState.breadcrumbs.findIndex((bc) => bc.current);

  const position =
    action.position === 'current' ? oldCurrent - 1 : action.position;

  let updatedBreadcrumbs = update(breadcrumbsState, {
    breadcrumbs: {
      $splice: [[position + 1]],
      [position]: {
        $merge: {
          current: true,
        },
      },
    },
  });

  if (oldCurrent > -1 && updatedBreadcrumbs.breadcrumbs[oldCurrent]) {
    updatedBreadcrumbs = update(updatedBreadcrumbs, {
      breadcrumbs: {
        [oldCurrent]: {
          $merge: {
            current: false,
          },
        },
      },
    });
  }

  return {
    ...state,
    ...updatedBreadcrumbs,
  };
};
