import { Button } from '@mui/material';
import { IRFIDCardModel, RfidCardOrderStatus } from '../../../types/subscriber';
import React, { useEffect, useState } from 'react';
import connector, { IPropsFromState } from '../../Connector/Connector';

import CardImage from '../CardImage/CardImage';
import ContactlessIcon from '@mui/icons-material/Contactless';
import { DialogChargingCard } from '../DialogChargingCard';
import { IChargingRecordViewModel } from '../../../types/chargingRecords';
import { formatIsoDates } from '../../../utils/dates';
import { lexicographical } from '../../../utils/comparisonFunctions';
import Tooltip from '../../ui/Tooltip';
import useStyles from './useStyles';
import { SubscriptionStatusComponent } from '../../SubscriptionStatus/SubscriptionStatusComponent';
import { Typography } from '../../shared/Typography/Typography';

type TProps = IPropsFromState & {
  chargingCard: IRFIDCardModel;
  defaultCard?: boolean;
  isRearSide?: boolean;
  showOverlay?: boolean;
  showExtraDetails?: boolean;
};

export const Card: React.FC<TProps> = ({
  chargingCard,
  addBreadcrumb,
  isRearSide = false,
  showOverlay = true,
  chargingSessionsState,
  showExtraDetails = true,
}): JSX.Element | null => {
  const [chargingSessions, setChargingSessions] = useState<
    IChargingRecordViewModel[]
  >([]);
  const classes = useStyles();
  const chargingSessionsData = chargingSessionsState;

  // Use state for displaying the overlay to make testing more reliable. Using CSS to set
  // visibility works, but the tests are unable to reliably check whether an object is actually
  // visible or not, see https://github.com/testing-library/jest-dom/issues/444
  const [cardHovered, setCardHovered] = useState(false);

  useEffect(() => {
    if (chargingSessionsData) {
      setChargingSessions(chargingSessionsData.data || []);
    }
  }, [chargingSessionsData]);

  const openDetailsPanel = (component: string, friendlyText: string) => {
    const data = {
      component,
      friendlyText,
      level: 1,
      componentId: chargingCard.id,
    };

    addBreadcrumb({ data });
  };

  const getLastTimeUsed = (): string => {
    const cardSessions = chargingSessions.filter(
      (cs) => cs.card_id === chargingCard.id
    );
    if (cardSessions.length) {
      cardSessions.sort((cs1, cs2) => {
        const date1 = new Date(cs1.start_date_time);
        const date2 = new Date(cs2.start_date_time);
        return lexicographical(date1, date2) * -1;
      });

      return formatIsoDates(cardSessions[0].start_date_time);
    }

    return '';
  };

  const ExtraDetails = (): JSX.Element => {
    return (
      <>
        {getLastTimeUsed() && (
          <div className={classes.cardCaptionWrapper}>
            <span data-testid='last-time-used-label'>
              Last time used: {getLastTimeUsed()}
            </span>
          </div>
        )}
        {chargingCard.subscription_id && (
          <div className={classes.cardCaptionWrapper}>
            <ContactlessIcon className={classes.contactlessIcon} />
            <span data-testid='linked-card-label'>
              Linked to Charging Subscription
            </span>
          </div>
        )}
      </>
    );
  };

  const getTooltip = () => (
    <Tooltip
      tooltipName='more-info'
      additionalText={chargingCard.number}
      className={classes.infoIcon}
    >
      <DialogChargingCard
        rfidUid={chargingCard.id}
        cardNumber={chargingCard.number}
        tag={chargingCard.tag}
      />
    </Tooltip>
  );

  const CustomStatusLabel: React.FC<{ label: string }> = ({ label }) => {
    return (
      <Typography
        className={classes.statusText}
        data-testid='subscription-status'
        variant='body2'
      >
        {label}
      </Typography>
    );
  };

  return (
    <>
      <div
        onPointerEnter={() => setCardHovered(true)}
        onPointerLeave={() => setCardHovered(false)}
        className={classes.cardWrapper}
        data-testid='charging-card'
      >
        {showOverlay && cardHovered && (
          <div data-testid='card-overlay' className={classes.cardOverlay}>
            <Button
              className={classes.cardDetailsButton}
              color='primary'
              variant='contained'
              data-testid='hover-details-button'
              onClick={() =>
                openDetailsPanel('ChargingCardDetails', 'Charging Card')
              }
            >
              Details
            </Button>
          </div>
        )}
        <div className={classes.cardBackgroundGradient} />
        <CardImage
          back={isRearSide}
          brandId={chargingCard.plasticard_brand_id}
          templateId={chargingCard.plasticard_design_template}
        />
        {!isRearSide && (
          <>
            <span
              className={classes.cardNumber}
              data-testid='charging-card-number'
            >
              <span style={{ lineHeight: 1 }}>
                {showOverlay ? chargingCard.number : getTooltip()}
              </span>
            </span>
            <div className={classes.outerStatusWrapper}>
              <SubscriptionStatusComponent
                status={chargingCard.status as RfidCardOrderStatus}
                CustomLabelComponent={CustomStatusLabel}
              />
            </div>
            {chargingCard.label && (
              <div className={classes.cardLabelWrapper}>
                <span className={classes.cardLabelText}>
                  {chargingCard.label}
                </span>
              </div>
            )}
          </>
        )}
      </div>
      {showExtraDetails && <ExtraDetails />}
    </>
  );
};

export default connector(Card);
